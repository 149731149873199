<template>
  <div id="app" :lang="$root.$i18n.locale">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
html,
body,
#app {
  margin: 0;
  padding: 0;
  overflow: auto;
  min-height: 100vh;
}

#app {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.container {
  width: 100%;
}

a,
button,
input {
  outline: none;
}

* {
  text-size-adjust: none !important;
}

button.loading {
  position: relative !important;
  pointer-events: auto !important;
  cursor: default !important;
  color: rgba(0, 0, 0, 0) !important;
  text-shadow: none !important;
}
button.loading::after,
button.loading::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem;
  height: 1rem;
  margin-left: -0.7rem;
  margin-top: -0.7rem;
  border-radius: 100%;
  border: 0.2rem solid rgba(255, 255, 255, 0.15);
}
button.loading::after {
  border-left-color: #fff;
  animation: spin 0.7s linear;
  animation-iteration-count: infinite;
}

@media only screen and (min-width: 1024px) {
  .container {
    max-width: 360px;
    margin: auto 10%;
  }
}

@keyframes spin {
  100% {
    transform: rotate(359deg);
  }
}
</style>
