import Vue from "vue";
import App from "./App.vue";

import "normalize.css";
import "purecss/build/base-min.css";
import "purecss/build/grids-min.css";
import "animate.css";

import "@fortawesome/fontawesome-free/css/all.min.css";

//#region vue-i18n
import VueI18n from "vue-i18n";
import messages from "@/locales/Common";

Vue.use(VueI18n);
const i18n = new VueI18n({
  messages,
  locale: "en",
  fallbackLocale: "en",
  localeDir: "@/locales"
});
//#endregion

//#region vue-router
import VueRouter from "vue-router";
import routes from "@/includes/routes";
const router = new VueRouter({ mode: "history", routes });

Vue.use(VueRouter);
//#endregion

// Mixin
import PageTitleMixin from "@/mixin/PageTitleMixin";
Vue.mixin(PageTitleMixin);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app');
