export default {
    en: {
        oauth2: {
            NetworkError: {
                title: "Network Error!",
                description: "Check your device's Internet connection."
            },
            error_403: {
                title: "Access denied!",
                description: "Check your Id and password and try again."
            },
            error_408: {
                title: "Can not access to A6 Touch Panel!",
                descriptions: [
                    "Please check if your A6 Touch Panel is connected to the Internet.",
                    "Make sure you have entered the correct A6 Touch Panel ID."
                ]
            }
        }
    },
    tr: {
        oauth2: {
            NetworkError: {
                title: "Bağlantı Hatası!",
                description: "Cihazınızın İnternet bağlantısını kontrol edin."
            },
            error_403: {
                title: "Oturum Açılamadı!",
                description: "Bilgilerinizi kontrol ederek tekrar deneyin."
            },
            error_408: {
                title: "A6 Touch Panele erişilemiyor!",
                descriptions: [
                    "A6 Touch Panelin İnternet bağlantısını kontrol edin.",
                    "Doğru cihaz Id'si girdiğinize emin olun."
                ]
            }
        }
    }
};